import { ChakraProvider, extendBaseTheme, extendTheme } from '@chakra-ui/react';
import React, { FC, ReactNode } from 'react';

const theme= extendTheme({
    colors: {
        primary: {
            50: '#e9f9e6',
            100: '#cbe9c6',
            200: '#abd8a5',
            300: '#8bc882',
            400: '#6cb960',
            500: '#529f46',
            600: '#3f7c36',
            700: '#2c5825',
            800: '#183614',
            900: '#011400',
        },
    },
})

export const Wrapper: FC<{ element: ReactNode }> = ({ element }) => {
    return (
        <ChakraProvider resetCSS theme={theme}>
            {element}
        </ChakraProvider>
    )
}
